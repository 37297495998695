import gql from "graphql-tag";

export const CREATE_CAMPAIGN = gql`
  mutation CREATE_CAMPAIGN($input: CreateBouncebackCampaignInput!) {
    bouncebackCampaignCreate(input: $input) {
      bouncebackCampaign {
        id
        name
        earnMinSpend
        redeemMinSpend
        redeemAmount
        redeemStartOn
        redeemEndOn
        earnStartOn
        earnEndOn
        redeemable
        earnable
        active
      }
      errors
    }
  }
`;

export const END_CAMPAIGN = gql`
  mutation END_CAMPAIGN($input: EndBouncebackCampaignInput!) {
    bouncebackCampaignEnd(input: $input) {
      bouncebackCampaign {
        id
        name
      }
      errors
    }
  }
`;

export const CLONE_CAMPAIGN = gql`
  mutation CLONE_CAMPAIGN($input: CloneBouncebackCampaignInput!) {
    bouncebackCampaignClone(input: $input) {
      bouncebackCampaign {
        id
      }
      errors
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UPDATE_CAMPAIGN($input: UpdateBouncebackCampaignInput!) {
    bouncebackCampaignUpdate(input: $input) {
      bouncebackCampaign {
        id
        name
        earnStartOn
        earnEndOn
        redeemStartOn
        redeemEndOn
        earnMinSpend
        earnMultiple
        redeemMinSpend
        redeemAmount
        campaignType
        reminderText
        intent
        createdAt
        updatedAt
      }
      errors
    }
  }
`;
