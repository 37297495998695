<template>
  <VxForm
    v-slot="{ submitting }"
    data-testid="campaignForm"
    class="campaign-form"
    :disabled="loading"
    :readonly="readonly"
    @submit="submit"
  >
    <VxFormGroup :title="$t('bounceback.setup.campaignForm.nameSection.title')">
      <v-row>
        <v-col cols="12" md="4">
          <VxTextField
            v-model="form.name"
            data-testid="bouncebackCampaignNameField"
            :label="
              $t('bounceback.setup.campaignForm.nameSection.nameFieldLabel')
            "
            :placeholder="
              $t(
                'bounceback.setup.campaignForm.nameSection.nameFieldPlaceholder'
              )
            "
            name="campaignName"
            rules="required"
            outlined
          />
        </v-col>
      </v-row>
    </VxFormGroup>
    <VxFormGroup :title="$t('bounceback.setup.campaignForm.typeSection.title')">
      <VxRadioGroup v-model="form.campaignType" name="campaignType">
        <VxRadio
          value="automatic"
          data-testid="bouncebackCampaignAutomaticRadioButton"
        >
          <template #label>
            <span class="mr-1">
              {{ $t("bounceback.setup.campaignForm.typeSection.automation") }}
            </span>
            <VxTooltip type="info" bottom>
              {{
                $t(
                  "bounceback.setup.campaignForm.typeSection.automationDescription"
                )
              }}
            </VxTooltip>
          </template>
        </VxRadio>
        <VxRadio
          data-testid="bouncebackCampaignManualRadioButton"
          :label="
            $t('bounceback.setup.campaignForm.typeSection.manualRadioLabel')
          "
          value="manual"
        />
      </VxRadioGroup>
      <v-row>
        <v-col cols="12" md="4">
          <VxSelect
            v-model="form.intent"
            data-testid="bouncebackCampaignIntentSelect"
            :label="
              $t('bounceback.setup.campaignForm.typeSection.intentSelectLabel')
            "
            :placeholder="
              $t(
                'bounceback.setup.campaignForm.typeSection.intentSelectPlaceholder'
              )
            "
            :items="intents"
            name="intent"
            rules="required"
            :menu-props="{ contentClass: 'campaign-intent-select-list' }"
            outlined
          />
        </v-col>
        <v-col v-if="showIntentOtherField" cols="12" md="4">
          <VxTextField
            v-model="form.intentOther"
            data-testid="bouncebackCampaignIntentOtherField"
            :label="
              $t('bounceback.setup.campaignForm.typeSection.otherFieldLabel')
            "
            name="intentOther"
            rules="required"
            outlined
          />
        </v-col>
      </v-row>
      <VxAlert type="info">
        <p>
          <strong>
            {{ $t("bounceback.setup.campaignForm.typeSection.note") }}
          </strong>
          {{ $t("bounceback.setup.campaignForm.typeSection.noteContent") }}
        </p>
        <p>
          {{ $t("bounceback.setup.campaignForm.typeSection.noteContent2") }}
        </p>
      </VxAlert>
    </VxFormGroup>
    <VxFormGroup
      :title="$t('bounceback.setup.campaignForm.earningPeriodSection.title')"
    >
      <p>
        {{
          $t("bounceback.setup.campaignForm.earningPeriodSection.description")
        }}
      </p>
      <v-row>
        <v-col cols="12" sm="5" md="4">
          <VxDatePicker
            v-model="form.earnStartOn"
            data-testid="bouncebackCampaignEarnStartField"
            :label="
              $t(
                'bounceback.setup.campaignForm.earningPeriodSection.startField.label'
              )
            "
            name="earnStartOn"
            :placeholder="
              $t(
                'bounceback.setup.campaignForm.earningPeriodSection.startField.placeholder'
              )
            "
            :min="new Date()"
            :max="form.earnEndOn"
            rules="required"
            outlined
            @change="onEarnStartOnChange"
          />
        </v-col>
        <v-col cols="12" sm="2" md="1">
          <div class="d-flex align-center justify-center" style="height: 65%">
            <v-icon v-if="isDesktop || $vuetify.breakpoint.sm" large>
              $arrowRight
            </v-icon>
            <v-icon v-else large>$arrowDown</v-icon>
          </div>
        </v-col>
        <v-col cols="12" sm="5" md="4">
          <VxDatePicker
            v-model="form.earnEndOn"
            data-testid="bouncebackCampaignEarnEndField"
            :label="
              $t(
                'bounceback.setup.campaignForm.earningPeriodSection.endField.label'
              )
            "
            name="earnEndOn"
            :placeholder="
              $t(
                'bounceback.setup.campaignForm.earningPeriodSection.endField.placeholder'
              )
            "
            :min="form.earnStartOn"
            :disabled="!form.earnStartOn"
            rules="required"
            outlined
            @change="onEarnEndOnChange"
          />
        </v-col>
      </v-row>
    </VxFormGroup>
    <VxFormGroup
      :title="$t('bounceback.setup.campaignForm.redemptionPerionSection.title')"
    >
      <p>
        {{
          $t(
            "bounceback.setup.campaignForm.redemptionPerionSection.description"
          )
        }}
      </p>
      <v-row>
        <v-col cols="12" sm="5" md="4">
          <VxDatePicker
            v-model="form.redeemStartOn"
            data-testid="bouncebackCampaignRedeemStartField"
            :label="
              $t(
                'bounceback.setup.campaignForm.redemptionPerionSection.startField.label'
              )
            "
            name="redeemStartOn"
            :placeholder="
              $t(
                'bounceback.setup.campaignForm.redemptionPerionSection.startField.placeholder'
              )
            "
            :min="form.earnStartOn || new Date()"
            :max="form.redeemEndOn"
            rules="required"
            outlined
          />
        </v-col>
        <v-col cols="12" sm="2" md="1">
          <div class="d-flex align-center justify-center" style="height: 65%">
            <v-icon v-if="isDesktop || $vuetify.breakpoint.sm" large>
              $arrowRight
            </v-icon>
            <v-icon v-else large>$arrowDown</v-icon>
          </div>
        </v-col>
        <v-col cols="12" sm="5" md="4">
          <VxDatePicker
            v-model="form.redeemEndOn"
            data-testid="bouncebackCampaignRedeemEndField"
            :label="
              $t(
                'bounceback.setup.campaignForm.redemptionPerionSection.endField.label'
              )
            "
            name="redeemEndOn"
            :placeholder="
              $t(
                'bounceback.setup.campaignForm.redemptionPerionSection.endField.placeholder'
              )
            "
            :min="redeemEndMin"
            :disabled="!form.redeemStartOn"
            rules="required"
            outlined
          />
        </v-col>
      </v-row>
    </VxFormGroup>
    <VxFormGroup
      :title="$t('bounceback.setup.campaignForm.couponDetailsSection.title')"
    >
      <template v-if="isAutomatic">
        <p>
          <span class="mr-2">
            {{
              $t(
                "bounceback.setup.campaignForm.couponDetailsSection.automatic.description"
              )
            }}
          </span>
          <VxTextField
            v-model.number="form.earnMinSpend"
            data-testid="bouncebackCampaignEarnMinSpendField"
            :label="
              $t(
                'bounceback.setup.campaignForm.couponDetailsSection.automatic.spendFieldLabel'
              )
            "
            type="number"
            name="earnMinSpend"
            class="d-inline-block"
            style="width: 150px"
            rules="required|numeric|min_value:1"
            min="1"
            prefix="$"
          />.
        </p>
        <VxRadioGroup v-model="form.earnMultiple" name="earnMultiple">
          <VxRadio
            data-testid="bouncebackCampaignEarnMultipleTrueRadioButton"
            :label="
              $t(
                'bounceback.setup.campaignForm.couponDetailsSection.automatic.earnMultipleLabel'
              )
            "
            :value="true"
          />
          <VxRadio
            data-testid="bouncebackCampaignEarnMultipleFalseRadioButton"
            :label="
              $t(
                'bounceback.setup.campaignForm.couponDetailsSection.automatic.limiToOneLabel'
              )
            "
            :value="false"
          />
        </VxRadioGroup>
      </template>
      <template v-else>
        <p>
          {{
            $t(
              "bounceback.setup.campaignForm.couponDetailsSection.nonAutomatic.description"
            )
          }}
        </p>
      </template>
      <p>
        <span>
          {{
            $t(
              "bounceback.setup.campaignForm.couponDetailsSection.discountText1"
            )
          }}
        </span>
        <VxTextField
          v-model.number="form.redeemAmount"
          data-testid="bouncebackCampaignRedeemAmountField"
          :label="
            $t(
              'bounceback.setup.campaignForm.couponDetailsSection.discountFieldLabel'
            )
          "
          type="number"
          name="redeemAmount"
          class="d-inline-block mx-2"
          style="width: 150px"
          rules="required|numeric|min_value:1|max_value:@redeemMinSpend"
          min="1"
          :max="form.redeemMinSpend"
          prefix="$"
        />
        <span>
          {{
            $t(
              "bounceback.setup.campaignForm.couponDetailsSection.discountText2"
            )
          }}
        </span>
        <VxTextField
          v-model.number="form.redeemMinSpend"
          data-testid="bouncebackCampaignRedeemMinSpendField"
          :label="
            $t(
              'bounceback.setup.campaignForm.couponDetailsSection.purchaseFieldLabel'
            )
          "
          type="number"
          name="redeemMinSpend"
          class="d-inline-block mx-2"
          style="width: 150px"
          rules="required|numeric|min_value:1"
          min="1"
          prefix="$"
        />
        <span>
          {{
            $t(
              "bounceback.setup.campaignForm.couponDetailsSection.discountText3"
            )
          }}
        </span>
        <VxTooltip type="info" bottom>
          {{
            $t("bounceback.setup.campaignForm.couponDetailsSection.infoText")
          }}
        </VxTooltip>
      </p>
    </VxFormGroup>
    <VxFormGroup
      :title="$t('bounceback.setup.campaignForm.remainderSection.title')"
    >
      <VxCheckbox
        v-model="form.reminderText"
        data-testid="bouncebackCampaignReminderTextSwitch"
        :label="
          $t(
            'bounceback.setup.campaignForm.remainderSection.reminderRadioLabel'
          )
        "
        name="reminderText"
      />
      <VxAlert type="info">
        <p>
          <strong>{{
            $t("bounceback.setup.campaignForm.remainderSection.note")
          }}</strong>
          {{ $t("bounceback.setup.campaignForm.remainderSection.noteContent") }}
        </p>
      </VxAlert>
    </VxFormGroup>
    <div class="campaign-form__errors">
      <VxAlert v-for="error in errors" :key="error" type="error">
        {{ error }}
      </VxAlert>
    </div>
    <VxFormActions class="flex-column-reverse flex-md-row">
      <slot :data="form"></slot>
      <VxBtn
        v-if="!readonly"
        data-testid="bouncebackCampaignFormSubmitButton"
        type="submit"
        :block="isMobile"
        :disabled="loading"
        :loading="submitting"
        class="mb-4 mb-md-0"
        large
      >
        <v-icon left>$save</v-icon>
        {{ $t("bounceback.setup.campaignForm.saveButton") }}
      </VxBtn>
    </VxFormActions>
  </VxForm>
</template>

<script>
import isEqual from "lodash/isEqual";

import { isAfter, parseISO } from "date-fns";
import { useFormObject } from "@/mixins/useFormObject";
import { CampaignType, IntentType } from "../../constants";
import {
  VxTextField,
  VxDatePicker,
  VxAlert,
  VxSelect,
  VxForm,
  VxFormGroup,
  VxFormActions,
  VxRadio,
  VxRadioGroup,
  VxTooltip,
  VxBtn,
  VxCheckbox,
  useResponsiveness,
} from "@/core-ui";

export default {
  name: "CampaignForm",
  components: {
    VxTextField,
    VxDatePicker,
    VxAlert,
    VxSelect,
    VxForm,
    VxFormGroup,
    VxFormActions,
    VxTooltip,
    VxRadio,
    VxRadioGroup,
    VxBtn,
    VxCheckbox,
  },
  mixins: [
    useResponsiveness(),
    useFormObject({
      prop: "campaign",
      handler: "campaignHandler",
      default: {
        id: 0,
        name: "",
        campaignType: CampaignType.AUTOMATIC,
        intent: "",
        intentOther: "",
        earnStartOn: null,
        earnEndOn: null,
        redeemStartOn: null,
        redeemEndOn: null,
        earnMinSpend: null,
        redeemAmount: null,
        redeemMinSpend: null,
        reminderText: true,
        earnMultiple: true,
      },
    }),
  ],
  props: {
    intents: {
      type: Array,
      default: () => Object.values(IntentType),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    redeemEndMin() {
      return isAfter(
        parseISO(this.form.earnEndOn),
        parseISO(this.form.redeemStartOn)
      )
        ? this.form.earnEndOn
        : this.form.redeemStartOn;
    },
    showIntentOtherField() {
      return this.form.intent === IntentType.OTHER;
    },
    isAutomatic() {
      return this.form.campaignType === CampaignType.AUTOMATIC;
    },
  },
  methods: {
    onEarnStartOnChange() {
      if (this.form.earnStartOn > this.form.redeemStartOn) {
        this.form.redeemStartOn = null;
      }
    },
    onEarnEndOnChange() {
      if (this.form.earnEndOn > this.form.redeemEndOn) {
        this.form.redeemEndOn = null;
      }
    },
    submit(formContext) {
      this.$emit("submit", {
        ...formContext,
        values: { ...this.campaignHandler(this.form) },
        changed: !isEqual(this.form, this.campaign),
        cloneable: this.shouldClone(this.form),
      });
    },
    campaignHandler(values) {
      const { campaignType, intent = "" } = values;

      if (intent && this.intents.indexOf(intent) === -1) {
        values.intent = IntentType.OTHER;
        values.intentOther = intent;
      } else if (intent !== IntentType.OTHER) {
        values.intentOther = "";
      }

      if (campaignType === CampaignType.MANUAL) {
        values.earnMinSpend = null;
      }

      return values;
    },
    shouldClone(values) {
      return (
        this.campaign.id > 0 &&
        this.campaign.hasCoupons === true &&
        Object.entries(this.campaign)
          .filter(([key, value]) => value !== values[key])
          .some(
            ([key, _]) =>
              key !== "earnStartOn" &&
              key !== "earnEndOn" &&
              key !== "reminderText"
          ) // -> returns true if any of the changed values are not earnStartOn, earnEndOn and reminderText
      );
    },
  },
};
</script>
