var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VxForm',{staticClass:"campaign-form",attrs:{"data-testid":"campaignForm","disabled":_vm.loading,"readonly":_vm.readonly},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function({ submitting }){return [_c('VxFormGroup',{attrs:{"title":_vm.$t('bounceback.setup.campaignForm.nameSection.title')}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('VxTextField',{attrs:{"data-testid":"bouncebackCampaignNameField","label":_vm.$t('bounceback.setup.campaignForm.nameSection.nameFieldLabel'),"placeholder":_vm.$t(
              'bounceback.setup.campaignForm.nameSection.nameFieldPlaceholder'
            ),"name":"campaignName","rules":"required","outlined":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)],1),_c('VxFormGroup',{attrs:{"title":_vm.$t('bounceback.setup.campaignForm.typeSection.title')}},[_c('VxRadioGroup',{attrs:{"name":"campaignType"},model:{value:(_vm.form.campaignType),callback:function ($$v) {_vm.$set(_vm.form, "campaignType", $$v)},expression:"form.campaignType"}},[_c('VxRadio',{attrs:{"value":"automatic","data-testid":"bouncebackCampaignAutomaticRadioButton"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$t("bounceback.setup.campaignForm.typeSection.automation"))+" ")]),_c('VxTooltip',{attrs:{"type":"info","bottom":""}},[_vm._v(" "+_vm._s(_vm.$t( "bounceback.setup.campaignForm.typeSection.automationDescription" ))+" ")])]},proxy:true}],null,true)}),_c('VxRadio',{attrs:{"data-testid":"bouncebackCampaignManualRadioButton","label":_vm.$t('bounceback.setup.campaignForm.typeSection.manualRadioLabel'),"value":"manual"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('VxSelect',{attrs:{"data-testid":"bouncebackCampaignIntentSelect","label":_vm.$t('bounceback.setup.campaignForm.typeSection.intentSelectLabel'),"placeholder":_vm.$t(
              'bounceback.setup.campaignForm.typeSection.intentSelectPlaceholder'
            ),"items":_vm.intents,"name":"intent","rules":"required","menu-props":{ contentClass: 'campaign-intent-select-list' },"outlined":""},model:{value:(_vm.form.intent),callback:function ($$v) {_vm.$set(_vm.form, "intent", $$v)},expression:"form.intent"}})],1),(_vm.showIntentOtherField)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('VxTextField',{attrs:{"data-testid":"bouncebackCampaignIntentOtherField","label":_vm.$t('bounceback.setup.campaignForm.typeSection.otherFieldLabel'),"name":"intentOther","rules":"required","outlined":""},model:{value:(_vm.form.intentOther),callback:function ($$v) {_vm.$set(_vm.form, "intentOther", $$v)},expression:"form.intentOther"}})],1):_vm._e()],1),_c('VxAlert',{attrs:{"type":"info"}},[_c('p',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("bounceback.setup.campaignForm.typeSection.note"))+" ")]),_vm._v(" "+_vm._s(_vm.$t("bounceback.setup.campaignForm.typeSection.noteContent"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("bounceback.setup.campaignForm.typeSection.noteContent2"))+" ")])])],1),_c('VxFormGroup',{attrs:{"title":_vm.$t('bounceback.setup.campaignForm.earningPeriodSection.title')}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("bounceback.setup.campaignForm.earningPeriodSection.description"))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"4"}},[_c('VxDatePicker',{attrs:{"data-testid":"bouncebackCampaignEarnStartField","label":_vm.$t(
              'bounceback.setup.campaignForm.earningPeriodSection.startField.label'
            ),"name":"earnStartOn","placeholder":_vm.$t(
              'bounceback.setup.campaignForm.earningPeriodSection.startField.placeholder'
            ),"min":new Date(),"max":_vm.form.earnEndOn,"rules":"required","outlined":""},on:{"change":_vm.onEarnStartOnChange},model:{value:(_vm.form.earnStartOn),callback:function ($$v) {_vm.$set(_vm.form, "earnStartOn", $$v)},expression:"form.earnStartOn"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"1"}},[_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"65%"}},[(_vm.isDesktop || _vm.$vuetify.breakpoint.sm)?_c('v-icon',{attrs:{"large":""}},[_vm._v(" $arrowRight ")]):_c('v-icon',{attrs:{"large":""}},[_vm._v("$arrowDown")])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"4"}},[_c('VxDatePicker',{attrs:{"data-testid":"bouncebackCampaignEarnEndField","label":_vm.$t(
              'bounceback.setup.campaignForm.earningPeriodSection.endField.label'
            ),"name":"earnEndOn","placeholder":_vm.$t(
              'bounceback.setup.campaignForm.earningPeriodSection.endField.placeholder'
            ),"min":_vm.form.earnStartOn,"disabled":!_vm.form.earnStartOn,"rules":"required","outlined":""},on:{"change":_vm.onEarnEndOnChange},model:{value:(_vm.form.earnEndOn),callback:function ($$v) {_vm.$set(_vm.form, "earnEndOn", $$v)},expression:"form.earnEndOn"}})],1)],1)],1),_c('VxFormGroup',{attrs:{"title":_vm.$t('bounceback.setup.campaignForm.redemptionPerionSection.title')}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "bounceback.setup.campaignForm.redemptionPerionSection.description" ))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"4"}},[_c('VxDatePicker',{attrs:{"data-testid":"bouncebackCampaignRedeemStartField","label":_vm.$t(
              'bounceback.setup.campaignForm.redemptionPerionSection.startField.label'
            ),"name":"redeemStartOn","placeholder":_vm.$t(
              'bounceback.setup.campaignForm.redemptionPerionSection.startField.placeholder'
            ),"min":_vm.form.earnStartOn || new Date(),"max":_vm.form.redeemEndOn,"rules":"required","outlined":""},model:{value:(_vm.form.redeemStartOn),callback:function ($$v) {_vm.$set(_vm.form, "redeemStartOn", $$v)},expression:"form.redeemStartOn"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"1"}},[_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"65%"}},[(_vm.isDesktop || _vm.$vuetify.breakpoint.sm)?_c('v-icon',{attrs:{"large":""}},[_vm._v(" $arrowRight ")]):_c('v-icon',{attrs:{"large":""}},[_vm._v("$arrowDown")])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"4"}},[_c('VxDatePicker',{attrs:{"data-testid":"bouncebackCampaignRedeemEndField","label":_vm.$t(
              'bounceback.setup.campaignForm.redemptionPerionSection.endField.label'
            ),"name":"redeemEndOn","placeholder":_vm.$t(
              'bounceback.setup.campaignForm.redemptionPerionSection.endField.placeholder'
            ),"min":_vm.redeemEndMin,"disabled":!_vm.form.redeemStartOn,"rules":"required","outlined":""},model:{value:(_vm.form.redeemEndOn),callback:function ($$v) {_vm.$set(_vm.form, "redeemEndOn", $$v)},expression:"form.redeemEndOn"}})],1)],1)],1),_c('VxFormGroup',{attrs:{"title":_vm.$t('bounceback.setup.campaignForm.couponDetailsSection.title')}},[(_vm.isAutomatic)?[_c('p',[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t( "bounceback.setup.campaignForm.couponDetailsSection.automatic.description" ))+" ")]),_c('VxTextField',{staticClass:"d-inline-block",staticStyle:{"width":"150px"},attrs:{"data-testid":"bouncebackCampaignEarnMinSpendField","label":_vm.$t(
              'bounceback.setup.campaignForm.couponDetailsSection.automatic.spendFieldLabel'
            ),"type":"number","name":"earnMinSpend","rules":"required|numeric|min_value:1","min":"1","prefix":"$"},model:{value:(_vm.form.earnMinSpend),callback:function ($$v) {_vm.$set(_vm.form, "earnMinSpend", _vm._n($$v))},expression:"form.earnMinSpend"}}),_vm._v(". ")],1),_c('VxRadioGroup',{attrs:{"name":"earnMultiple"},model:{value:(_vm.form.earnMultiple),callback:function ($$v) {_vm.$set(_vm.form, "earnMultiple", $$v)},expression:"form.earnMultiple"}},[_c('VxRadio',{attrs:{"data-testid":"bouncebackCampaignEarnMultipleTrueRadioButton","label":_vm.$t(
              'bounceback.setup.campaignForm.couponDetailsSection.automatic.earnMultipleLabel'
            ),"value":true}}),_c('VxRadio',{attrs:{"data-testid":"bouncebackCampaignEarnMultipleFalseRadioButton","label":_vm.$t(
              'bounceback.setup.campaignForm.couponDetailsSection.automatic.limiToOneLabel'
            ),"value":false}})],1)]:[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "bounceback.setup.campaignForm.couponDetailsSection.nonAutomatic.description" ))+" ")])],_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "bounceback.setup.campaignForm.couponDetailsSection.discountText1" ))+" ")]),_c('VxTextField',{staticClass:"d-inline-block mx-2",staticStyle:{"width":"150px"},attrs:{"data-testid":"bouncebackCampaignRedeemAmountField","label":_vm.$t(
            'bounceback.setup.campaignForm.couponDetailsSection.discountFieldLabel'
          ),"type":"number","name":"redeemAmount","rules":"required|numeric|min_value:1|max_value:@redeemMinSpend","min":"1","max":_vm.form.redeemMinSpend,"prefix":"$"},model:{value:(_vm.form.redeemAmount),callback:function ($$v) {_vm.$set(_vm.form, "redeemAmount", _vm._n($$v))},expression:"form.redeemAmount"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t( "bounceback.setup.campaignForm.couponDetailsSection.discountText2" ))+" ")]),_c('VxTextField',{staticClass:"d-inline-block mx-2",staticStyle:{"width":"150px"},attrs:{"data-testid":"bouncebackCampaignRedeemMinSpendField","label":_vm.$t(
            'bounceback.setup.campaignForm.couponDetailsSection.purchaseFieldLabel'
          ),"type":"number","name":"redeemMinSpend","rules":"required|numeric|min_value:1","min":"1","prefix":"$"},model:{value:(_vm.form.redeemMinSpend),callback:function ($$v) {_vm.$set(_vm.form, "redeemMinSpend", _vm._n($$v))},expression:"form.redeemMinSpend"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t( "bounceback.setup.campaignForm.couponDetailsSection.discountText3" ))+" ")]),_c('VxTooltip',{attrs:{"type":"info","bottom":""}},[_vm._v(" "+_vm._s(_vm.$t("bounceback.setup.campaignForm.couponDetailsSection.infoText"))+" ")])],1)],2),_c('VxFormGroup',{attrs:{"title":_vm.$t('bounceback.setup.campaignForm.remainderSection.title')}},[_c('VxCheckbox',{attrs:{"data-testid":"bouncebackCampaignReminderTextSwitch","label":_vm.$t(
          'bounceback.setup.campaignForm.remainderSection.reminderRadioLabel'
        ),"name":"reminderText"},model:{value:(_vm.form.reminderText),callback:function ($$v) {_vm.$set(_vm.form, "reminderText", $$v)},expression:"form.reminderText"}}),_c('VxAlert',{attrs:{"type":"info"}},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("bounceback.setup.campaignForm.remainderSection.note")))]),_vm._v(" "+_vm._s(_vm.$t("bounceback.setup.campaignForm.remainderSection.noteContent"))+" ")])])],1),_c('div',{staticClass:"campaign-form__errors"},_vm._l((_vm.errors),function(error){return _c('VxAlert',{key:error,attrs:{"type":"error"}},[_vm._v(" "+_vm._s(error)+" ")])}),1),_c('VxFormActions',{staticClass:"flex-column-reverse flex-md-row"},[_vm._t("default",null,{"data":_vm.form}),(!_vm.readonly)?_c('VxBtn',{staticClass:"mb-4 mb-md-0",attrs:{"data-testid":"bouncebackCampaignFormSubmitButton","type":"submit","block":_vm.isMobile,"disabled":_vm.loading,"loading":submitting,"large":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$save")]),_vm._v(" "+_vm._s(_vm.$t("bounceback.setup.campaignForm.saveButton"))+" ")],1):_vm._e()],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }